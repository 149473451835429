import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useState } from 'react';
import { dequal } from "./dequal.js";
export function useStableValue(value) {
  var _useState = useState(function () {
    return value;
  }),
      _useState2 = _slicedToArray(_useState, 2),
      stableValue = _useState2[0],
      setStableValue = _useState2[1];

  if (!dequal(stableValue, value)) {
    setStableValue(value);
  }

  return stableValue;
}